<template>
  <div class="flex relative dark-bg">
    <left-bar 
      topBlockBorderColor="border-grey-light-200" 
      bottomBlockBorderColor="border-grey-light-200" 
      rightBlockBorderColor="border-grey-light-200"
      wrapBg="bg-transparent"
    >
    </left-bar>
    <div class="content w-full main-content min-h-screen pt-12">
      <FeatureNav 
        class="dark-bg mx-12" 
        headerBorder="border-grey-light-200"
        linkBorderColor="border-grey-light-200"
        :discovery="true"
      />
      <router-view />
    </div>
    <ProfileNav class="dark-bg" borderColor="border-grey-light-200" :discovery="true"/>
  </div>
</template>

<script>
  import FeatureNav from '@/components/FeatureNav';
  import ProfileNav from '@/components/ProfileNav'
  import LeftBar from '@/components/LeftBar.vue';

  export default {
    components: {
      FeatureNav, 
      ProfileNav, 
      LeftBar,
    }
  }
</script>

<style lang="scss" scoped>
</style>